import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal, Grid, Icon } from "semantic-ui-react";
import * as Chat from "../react-lib/apps/IsHealth/Chat";
import CardClassify from "../react-lib/apps/IsHealth/Nurse/CardClassify";
import CardPatientPanel from "../react-lib/apps/IsHealth/BIL/CardBILPatientPanel";
import CardDiagFormHistory from "../react-lib/apps/IsHealth/Nurse/CardDiagFormHistory";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import ModConfirm from "../react-lib/apps/common/ModConfirm";

const BILChatTable = React.forwardRef((props, ref) => {
  const history = useHistory();
  const isMounted = useRef(true);
  const [openClassify, setOpenClassify] = useState(false);
  const [openDoctorQueue, setOpenDoctorQueue] = useState(false);
  const [openModDiagHis, setOpenModDiagHis] = useState(false);
  const [encounterData, setEncounterData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [cookies] = useCookies([]);
  const [patientLoading, setPatientLoading] = React.useState(false);
  const chatListRef = React.useRef();
  const chatBoxRef = React.useRef();
  const patientPanelRef = React.useRef();
  const [readOnly, setReadOnly] = React.useState(false);
  const [openModAppoint, setOpenModAppoint] = React.useState(false);
  const [modAppointLoading, setModAppointLoading] = React.useState(false);
  const [modAppointError, setModAppointError] = React.useState(null);
  const [patientAppointmentId, setPatientAppointmentId] = React.useState(null);
  const [allClassify, setAllClassify] = React.useState([]);
  const [triageLevelList, setTriageLevelList] = React.useState([]);

  React.useImperativeHandle(ref, () => ({
    chatListRef: chatListRef.current,
    chatBoxRef: chatBoxRef.current,
    patientPanelRef: patientPanelRef.current,
    getPatientInfo: () => getPatientDetail()
  }));

  const openModalClassify = () => {
    setOpenClassify(true);
  };

  const closeModalClassify = () => {
    setOpenClassify(false);
  };

  React.useEffect(() => {
    handleGetClassify();
  }, [props.finishedTriageLevel, allClassify]);

  const handleGetClassify = async () => {
    if (!allClassify) {
      return;
    }
    let newArr = allClassify.filter(item => {
      if (
        props.finishedTriageLevel &&
        !props.finishedTriageLevel.includes(item.triage_level)
      ) {
        return item;
      }
    });
    let options = newArr.map(
      ({ triage_level, description, active, codeColor }) => ({
        key: triage_level,
        value: triage_level,
        triage_level: triage_level,
        code_color: codeColor,
        text: description,
        active: active
      })
    );
    setTriageLevelList([
      { key: "default", text: "DEFAULT", value: "default" },
      // { key: "app", text: "มีนัดหมาย", value: "app" },
      // { key: "noApp", text: "ไม่มีนัดหมาย", value: "noApp" },
      ...options
    ]);
  };

  React.useEffect(() => {
    props.onGetDivision();
    return () => (isMounted.current = false);
  }, []);

  React.useEffect(() => {
    if (cookies.apiToken || props.apiToken) {
      getClassifyUser();
    }
  }, [cookies.apiToken, props.apiToken]);

  const getClassifyUser = async () => {
    const [
      response,
      error,
      network
    ] = await props.controller.getClassifyUser({
      apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken
      // divisionId: props.division ? props.division : cookies.division,
    });
    if (!isMounted.current) {
      return;
    }
    if (response) {
      setAllClassify(response.classify);
    } else {
      setAllClassify([]);
      // setTriageLevelList([]);
    }
  };

  React.useEffect(() => {
    const getEncounterDetail = async () => {
      setPatientLoading(true);
      const [data, error] = await props.controller.getEncounterDetail({
        encounterId: props.match.params.encounterId,
        apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken
      });

      if (isMounted.current) {
        setPatientLoading(false);
        if (error) {
          console.log(error);
        } else {
          setEncounterData(data);
        }
      }
    };

    if (props.match.params.encounterId) {
      getEncounterDetail();
    }
  }, [props.match.params.encounterId]);

  React.useEffect(() => {
    setPatientLoading(true);

    if (encounterData.patient) {
      getPatientDetail();
    }
  }, [encounterData.patient]);

  const getPatientDetail = async () => {
    if (!encounterData.patient) {
      return;
    }
    const [data, error] = await props.controller.getProxyPatient({
      patientId: encounterData.patient,
      apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken
    });

    if (isMounted.current) {
      setPatientLoading(false);
      if (error) {
        console.log(error);
        setPatientData({});
      } else {
        setPatientData(data);
      }
    }
  };

  const handleModDoctorQueue = () => {
    setOpenDoctorQueue(!openDoctorQueue);
  };

  const handleModDiagHis = () => {
    setOpenModDiagHis(!openModDiagHis);
  };

  const handleEnNoDiv = () => {
    setReadOnly(true);
  };

  const handleEnHasDiv = () => {
    setReadOnly(false);
  };

  React.useEffect(() => {
    if (!openModAppoint) {
      setModAppointError(null);
    }
  }, [openModAppoint]);

  const handleOpenAppointment = async () => {
    if (props.match && props.match.params && props.match.params.encounterId) {
      setModAppointError(null);
      setModAppointLoading(true);
      const [
        response,
        error
      ] = await props.controller.postEncounterPatientAppointment({
        encounterId: props.match.params.encounterId,
        apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken
      });
      if (isMounted.current) {
        setModAppointLoading(false);
        chatBoxRef.current.getLastMessage();
        if (response) {
          setOpenModAppoint(false);
        } else {
          setModAppointError(error);
        }
      }
    }
  };

  const handleSetAppointmentId = ({ appointmentId } = {}) => {
    setPatientAppointmentId(appointmentId);
  };

  const handleCreateEditAppointment = () => {
    if (!patientAppointmentId) {
      setOpenModAppoint(true);
    } else {
      props.onEditAppointment({ appointmentId: patientAppointmentId });
    }
  };

  return (
    <Grid className="ChatTable noMargin" celled>
      <Modal
        open={openModDiagHis}
        closeOnDimmerClick
        onClose={handleModDiagHis}
        size="large"
      >
        <CardDiagFormHistory
          controller={props.diagFormController}
          hideCallback={handleModDiagHis}
          patientData={patientData}
          // userId={props.userId}
          // division={props.division}
          // apiToken={props.apiToken}
        />
      </Modal>
      {/* <Table.Body>
        <Table.Row> */}
      <Grid.Column width={4} style={{ padding: 0 }} className="chatList">
        <Chat.ChatList
          {...props}
          // Not send division because of #43603
          ref={chatListRef}
          notFilterDivision={true}
          controller={props.controller}
          userId={props.userId}
          apiToken={props.apiToken}
          // division={props.division}
          finishedTriageLevel={props.finishedTriageLevel}
          onSetAppointmentId={handleSetAppointmentId}
          triageLevelList={triageLevelList}
        />
      </Grid.Column>
      <Grid.Column width={8} style={{ padding: 0 }} className="chatBox">
        {props.match &&
        props.match.params &&
        props.match.params.chatChannelId ? (
          <>
            <Form className="chatbox-header">
              <Form.Group>
                <Form.Field>
                  <Button className="inverted-btn" onClick={handleModDiagHis}>
                    <Icon name="briefcase" />
                    แบบคัดกรอง
                  </Button>
                </Form.Field>
                <Form.Field>
                  <Button onClick={openModalClassify} disabled={readOnly}>
                    reclassify
                  </Button>
                </Form.Field>
              </Form.Group>
            </Form>
            <Chat.ChatBox
              {...props}
              ref={chatBoxRef}
              nullId={props.nullId}
              controller={props.controller}
              userId={props.userId}
              apiToken={props.apiToken}
              fullname={props.fullname}
              username={props.username}
              patientData={patientData}
              enHasNoDiv={handleEnNoDiv}
              enHasDiv={handleEnHasDiv}
              onNavigationMessage={props.onNavigationMessage}
              isPatient={props.isPatient}
            />
          </>
        ) : null}
      </Grid.Column>
      <Grid.Column width={4} style={{ padding: 0 }} className="chatDetail">
        {props.match && props.match.params && props.match.params.encounterId ? (
          <CardPatientPanel
            {...props}
            ref={patientPanelRef}
            readOnly={true}
            controller={props.patientPanelController}
            nurseNoteController={props.nurseNoteController}
            progressNoteController={props.progressNoteController}
            userId={props.userId}
            division={props.division}
            apiToken={props.apiToken}
            patientData={patientData}
            encounterData={encounterData}
            loading={patientLoading}
            triageLevelList={triageLevelList}
          />
        ) : null}
      </Grid.Column>
      {/* </Table.Row> */}
      {/* </Table.Body> */}

      <ModConfirm
        open={openModAppoint}
        alertText="ต้องการเปิดนัดหมายให้ผู้ป่วยใช่หรือไม่"
        onButtonLeftClick={() => setOpenModAppoint(false)}
        onButtonRightClick={handleOpenAppointment}
        loading={modAppointLoading}
        error={modAppointError}
      />

      <Modal open={openClassify} className="classify">
        <CardClassify
          controller={props.classifyController}
          hideCallback={closeModalClassify}
          userId={props.userId}
          division={props.division}
          apiToken={props.apiToken}
          patientData={patientData}
          encounterData={encounterData}
          // onSuccess={() => chatListRef.current.getChatList()}
        />
      </Modal>
    </Grid>
  );
});

BILChatTable.defaultProps = {
  className: "",
  onGetDivision: () => {},
  // ChatList
  chatListFetch: {},
  getLatestChatChannel: () => {},
  onSelectChat: () => {},

  // ChatBox
  messageFetch: {},
  userId: null,
  chatChannelId: null,
  onSendMessage: () => {},
  messagePost: {},

  // ChatDetail
  chatUserFetch: {},
  fullname: null,
  username: null,

  // DiagFormHistory
  diagFormController: null,

  finishedTriageLevel: [],
  allowCreateAppoint: false,
  isPatient: false
};

BILChatTable.propTypes = {
  onGetDivision: PropTypes.func,
  className: PropTypes.string,
  // ChatList
  chatListFetch: PropTypes.object,
  getLatestChatChannel: PropTypes.func,
  onSelectChat: PropTypes.func,

  // ChatBox
  messageFetch: PropTypes.object,
  userId: PropTypes.string,
  chatChannelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSendMessage: PropTypes.func,

  // ChatDetail
  chatUserFetch: PropTypes.object,
  fullname: PropTypes.string,
  username: PropTypes.string,

  // DiagFormHistroy
  diagFormController: PropTypes.object,
  finishedTriageLevel: PropTypes.array,
  allowCreateAppoint: PropTypes.bool,

  isPatient: PropTypes.bool
};

export default React.memo(BILChatTable);
